var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2 clearfix w-100 h-100 d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"w-100 h-100 position-absolute",staticStyle:{"filter":"blur(14px)","z-index":"-1"},style:('background: url(' + _vm.imgUrl + ') center center / cover;')}),_c('div',{staticClass:"fixed-top mr-3 mt-1 d-flex justify-content-end"},[_c('locale'),_c('dark-toggl')],1),_c('b-card',{staticStyle:{"width":"60rem"}},[_c('b-row',[_c('b-col',{staticClass:"p-5 rounded-left",staticStyle:{"margin":"-1.5rem -0.5rem"},style:('background: url(' + _vm.imgUrl + ') center center / cover;'),attrs:{"cols":"6"}},[_c('h4',{staticClass:"display-4 font-weight-lighter"},[_vm._v(" "+_vm._s(_vm.$t("Welcome"))+" ")]),_c('p',[_vm._v(_vm._s(_vm.$t("Slogan Description")))]),_c('h4',{staticClass:"text-right font-weight-lighter",staticStyle:{"margin-top":"12rem"},attrs:{"id":"text"},domProps:{"innerHTML":_vm._s(_vm.slogan)}})]),_c('b-col',{staticClass:"pr-3 pl-5 pt-3 pb-2",attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-items-center mb-4 justify-content-end"},[_c('b-icon-stars',{attrs:{"scale":"2"}}),_c('h2',{staticClass:"font-weight-bolder ml-2 my-0"},[_vm._v(" "+_vm._s(_vm.$t("Brand Name"))+" ")])],1),_c('h3',{staticClass:"font-weight-bolder mb-1"},[_vm._v(" "+_vm._s(_vm.$t("Sign In"))+" ")]),_c('small',[_vm._v(_vm._s(_vm.$t("Login Description")))]),_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-3",on:{"submit":function($event){$event.preventDefault();return _vm.login($event)}}},[_c('b-form-group',{staticClass:"mb-1",attrs:{"label-for":"login-username"}},[_c('label',{staticStyle:{"font-size":"1rem"}},[_vm._v(_vm._s(_vm.$t("UserName")))]),_c('validation-provider',{attrs:{"name":"userName","rules":"required","vid":"userName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-userName","placeholder":_vm.$t('UserName'),"state":errors.length > 0 ? false : null,"name":"login-userName"},model:{value:(_vm.userName),callback:function ($$v) {_vm.userName=$$v},expression:"userName"}})]}}],null,true)})],1),_c('b-form-group',{staticClass:"mb-1"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticStyle:{"font-size":"1rem"}},[_vm._v(_vm._s(_vm.$t("Password")))])]),_c('validation-provider',{attrs:{"name":"Password","rules":"required","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0
                                                ? 'is-invalid'
                                                : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","placeholder":_vm.$t('Password'),"state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"login-password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1)]}}],null,true)})],1),_c('b-form-group',{staticClass:"mt-2"},[_c('b-form-checkbox',{attrs:{"id":"remember-me","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_c('small',{staticClass:"position-relative",staticStyle:{"top":"-2px"}},[_vm._v(_vm._s(_vm.$t("RememberMe")))])])],1),_c('b-button',{staticClass:"mt-1",attrs:{"disabled":invalid,"block":"","type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("Sign In"))+" ")])],1)]}}])}),_c('b-card-text',{staticClass:"text-center mt-1"},[_c('span',[_vm._v(_vm._s(_vm.$t("RegisterTitle")))]),_c('b-link',[_c('span',[_vm._v(" "+_vm._s(_vm.$t("CreatAcount")))])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }