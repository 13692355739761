import useJwt from "@/libs/auth/jwt/useJwt";

const axios = useJwt.axiosIns;
/*
 * @Description: 注册接口
 * @Author: 张宇童
 * @Date: 2020/9/6 14:58
 */
export const registration = (data) => axios.post("/api/registration/", data);
/*
 * @Description: 登录接口
 * @Author: 张宇童
 * @Date: 2020/9/6 17:05
 */
export const login = (data) => axios.post("/api/login/", data);
