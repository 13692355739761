<template>
    <div
        class="auth-wrapper auth-v2 clearfix w-100 h-100 d-flex justify-content-center align-items-center"
    >
        <div
            :style="'background: url(' + imgUrl + ') center center / cover;'"
            class="w-100 h-100 position-absolute"
            style="filter: blur(14px); z-index: -1"
        ></div>
        <div class="fixed-top mr-3 mt-1 d-flex justify-content-end">
            <locale></locale>
            <dark-toggl></dark-toggl>
        </div>

        <b-card style="width: 60rem">
            <b-row>
                <b-col
                    :style="
                        'background: url(' + imgUrl + ') center center / cover;'
                    "
                    class="p-5 rounded-left"
                    cols="6"
                    style="margin: -1.5rem -0.5rem"
                >
                    <h4 class="display-4 font-weight-lighter">
                        {{ $t("Welcome") }}
                    </h4>
                    <p>{{ $t("Slogan Description") }}</p>

                    <h4
                        id="text"
                        class="text-right font-weight-lighter"
                        style="margin-top: 12rem"
                        v-html="slogan"
                    ></h4>
                </b-col>

                <b-col class="pr-3 pl-5 pt-3 pb-2" cols="6">
                    <div
                        class="d-flex align-items-center mb-4 justify-content-end"
                    >
                        <b-icon-stars scale="2" />
                        <h2 class="font-weight-bolder ml-2 my-0">
                            {{ $t("Brand Name") }}
                        </h2>
                    </div>

                    <h3 class="font-weight-bolder mb-1">
                        {{ $t("Sign In") }}
                    </h3>
                    <small>{{ $t("Login Description") }}</small>

                    <!-- form -->
                    <validation-observer ref="loginForm" #default="{ invalid }">
                        <b-form
                            class="auth-login-form mt-3"
                            @submit.prevent="login"
                        >
                            <!-- username -->
                            <b-form-group
                                class="mb-1"
                                label-for="login-username"
                            >
                                <label style="font-size: 1rem">{{
                                    $t("UserName")
                                }}</label>
                                <validation-provider
                                    #default="{ errors }"
                                    name="userName"
                                    rules="required"
                                    vid="userName"
                                >
                                    <b-form-input
                                        id="login-userName"
                                        v-model="userName"
                                        :placeholder="$t('UserName')"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                        name="login-userName"
                                    />
                                    <!--									<small class="text-danger">{{ errors[0] }}</small>-->
                                </validation-provider>
                            </b-form-group>

                            <!-- password -->
                            <b-form-group class="mb-1">
                                <div class="d-flex justify-content-between">
                                    <label style="font-size: 1rem">{{
                                        $t("Password")
                                    }}</label>
                                    <!-- <b-link :to="{ name: 'auth-forgot-password' }">
  <small>{{ $t("ForgotPassword") }}</small>
</b-link> -->
                                </div>
                                <validation-provider
                                    #default="{ errors }"
                                    name="Password"
                                    rules="required"
                                    vid="password"
                                >
                                    <b-input-group
                                        :class="
                                            errors.length > 0
                                                ? 'is-invalid'
                                                : null
                                        "
                                        class="input-group-merge"
                                    >
                                        <b-form-input
                                            id="login-password"
                                            v-model="password"
                                            :placeholder="$t('Password')"
                                            :state="
                                                errors.length > 0 ? false : null
                                            "
                                            :type="passwordFieldType"
                                            class="form-control-merge"
                                            name="login-password"
                                        />
                                        <b-input-group-append is-text>
                                            <feather-icon
                                                :icon="passwordToggleIcon"
                                                class="cursor-pointer"
                                                @click="
                                                    togglePasswordVisibility
                                                "
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <!--									<small class="text-danger">{{ errors[0] }}</small>-->
                                </validation-provider>
                            </b-form-group>

                            <!-- checkbox -->
                            <b-form-group class="mt-2">
                                <b-form-checkbox
                                    id="remember-me"
                                    v-model="status"
                                    name="checkbox-1"
                                >
                                    <small
                                        class="position-relative"
                                        style="top: -2px"
                                        >{{ $t("RememberMe") }}</small
                                    >
                                </b-form-checkbox>
                            </b-form-group>

                            <!-- submit buttons -->
                            <b-button
                                :disabled="invalid"
                                block
                                class="mt-1"
                                type="submit"
                                variant="primary"
                            >
                                {{ $t("Sign In") }}
                            </b-button>
                        </b-form>
                    </validation-observer>

                    <b-card-text class="text-center mt-1">
                        <span>{{ $t("RegisterTitle") }}</span>
                        <b-link>
                            <span>&nbsp;{{ $t("CreatAcount") }}</span>
                        </b-link>
                    </b-card-text>

                    <!-- divider -->
                    <!-- <div class="divider mt-2">
                        <div class="divider-text">Version</div>
                    </div>

                    <div class="text-center">
                        <v-select
                            v-model="selected"
                            :clearable="false"
                            :options="option"
                            class="select-size-sm d-inline-block"
                        >
                            <template #option="option">
                                {{ $t(option.label) }}
                            </template>
                            <template #selected-option="option">
                                {{ $t(option.label) }}
                            </template>
                        </v-select>
                    </div> -->
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from "vee-validate";
// import Locale from "@core/layouts/components/app-navbar/components/Locale.vue"
import useJwt from "@/libs/auth/jwt/useJwt";
import { login } from "@/api/auth";
import { required } from "@validations";
import { togglePasswordVisibility } from "@/@core/mixins/ui/forms";
import store from "@/store";
import { getHomeRouteForLoggedInUser } from "@/libs/auth/utils";
import Locale from "@/@core/layouts/components/app-navbar/components/Locale.vue";
import DarkToggl from "@/@core/layouts/components/app-navbar/components/DarkToggler.vue";
import { showToast } from "@/libs/utils/showToast";
import vSelect from "vue-select";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        Locale,
        DarkToggl,
        vSelect,
    },
    mixins: [togglePasswordVisibility, Locale],
    setup() {},
    data() {
        return {
            status: "",
            password: "",
            userName: "",
            sideImg: require("@/assets/images/pages/login-v2.svg"),
            // validation rules
            required,
            slogan: "_",
            slogan_timer: null,
            i: 0,
            selected: "general",
            option: [
                "general",
                "green",
                "zero-carbon",
                "nearly-zero",
                "health",
            ],
        };
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === "password"
                ? "EyeIcon"
                : "EyeOffIcon";
        },
        imgUrl() {
            if (store.state.appConfig.layout.skin === "dark") {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.sideImg = require("@/assets/images/bg/bg_4.jpeg");
            } else {
                this.sideImg = require("@/assets/images/bg/bg_3.jpeg");
            }
            return this.sideImg;
        },
    },

    methods: {
        login() {
            this.$refs.loginForm.validate().then((success) => {
                if (success) {
                    login({
                        user_name: this.userName,
                        password: this.password,
                    })
                        .then((response) => {
                            if (response.data) {
                                let { userData, accessToken, refreshToken } =
                                    response.data;
                                useJwt.setToken(accessToken);
                                useJwt.setRefreshToken(refreshToken);
                                // userData里有ability这个属性，需要给ability文件，作后续处理，这里把ability属性里的值添加到user_copy代替（先简单处理）
                                const user_copy = {
                                    ability: [
                                        {
                                            action: "manage",
                                            subject: "all",
                                        },
                                    ],
                                };
                                this.$ability.update(user_copy.ability);
                                let user_data = {
                                    ...userData,
                                    ...user_copy,
                                    ...{ role: "admin" },
                                };

                                store.commit(
                                    "users/UPDATE_USER_USERDATA",
                                    user_data
                                );
                                localStorage.setItem(
                                    "userData",
                                    JSON.stringify(user_data)
                                );

                                // ? This is just for pages purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                                if (
                                    [
                                        "green",
                                        "nearly-zero",
                                        "zero-carbon",
                                        "health",
                                    ].includes(this.selected)
                                ) {
                                    this.$router.push(
                                        `/7/construction/${this.selected}`
                                    );
                                } else {
                                    this.$router
                                        .replace(
                                            getHomeRouteForLoggedInUser(
                                                user_data.role
                                            )
                                        )
                                        .then(() => {
                                            showToast(
                                                null,
                                                "您好！登陆成功！",
                                                `${user_data.user_name}，欢迎您登陆!`
                                            );
                                        });
                                }
                            }
                        })
                        .catch((error) => {
                            showToast(error);
                            this.$refs.loginForm.setErrors(
                                error.response.data.message
                            );
                        });
                }
            });
        },
        typing() {
            if (this.i <= this.$t("Slogan").length) {
                this.slogan = this.$t("Slogan").slice(0, this.i++) + "|";
                this.slogan_timer = setTimeout(this.typing, 200);
            } else {
                this.slogan = this.$t("Slogan") + " |";
                clearTimeout(this.slogan_timer);
            }
        },
    },
    mounted() {
        let _this = this;
        setInterval(function () {
            _this.slogan = "";
            _this.i = 0;
            _this.slogan_timer = null;
            _this.typing();
        }, 5000);
    },
};
</script>

<style lang="scss">
@import "src/@core/scss/vue/pages/page-auth.scss";
@import "src/@core/scss/vue/libs/vue-select.scss";

li {
    list-style: none;
}
</style>
